<template>
  <div class="ai_chat">
    <p class="back_box">
      <span @click="backModule" class="back_btn hand">
        &lt; 返回
      </span>
    </p>
    <div class="content">
      <div class="chat_content">
        <div class="msg_content" ref="content">
          <div class="msg_list">
            <div class="msg_item" v-for="(item, i) in msgs" :key="i"
                 :class="{ 'send_msg': !item.isAi }">
              <div class="head_cover">
                <img v-if="!item.isAi" :src="userHeadCover" alt="">
                <i v-else class="ai_head_cover">In</i>
              </div>
              <div class="item_mag_text">
                <!-- <pre class="pre">{{item.message}}</pre> -->
                <div v-html="mdToHtml(item.message)"></div>
              </div>
            </div>

            <!-- 消息加载中 -->
            <div v-show="beforeOne.message" class="msg_item">
              <div class="head_cover">
                <i class="ai_head_cover">In</i>
              </div>
              <div class="item_mag_text">
                <!-- <pre class="pre">{{beforeOne.message}}</pre> -->
                <div v-html="mdToHtml(beforeOne.message)"></div>
              </div>
            </div>
          </div>

          <div class="send_box">
            <div class="send_input_box">
              <el-input @keyup.enter.native="sendMsg" v-model="kw"
                        placeholder="Please enter the message content"></el-input>
              <span class="send_btn" @click="sendMsg">
                <img v-if="!loading" src="@/assets/img/aiModule/send_icon.png" alt="">
                <i v-else class="el-icon-loading fs26"></i>
              </span>
            </div>
            <p class="chat_warning">本应用发出的信息由AI智能生成，请您自行分辩</p>
          </div>
        </div>

      </div>
      <div class="bg_box">
        <img src="@/assets/img/aiModule/ai_chat_bg_icon.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { getSessionId, confirm } from '@/utils/index.js'
// const marked = require('marked')
import { marked } from '@/utils/marked.esm.js'

export default {
  props: {},
  data () {
    return {
      loading: false,
      ws: {},
      kw: '',
      occupyTimeId: null,
      waitTimeId: null,
      msgs: [],
      beforeOne: {
        message: '',
        isAi: true
      },
      isOpenLinkState: false,
      system: {
        role: 'system',
        content: '你是AI智能助手（你的角色是一个全能医生助手、全能科研助手、全能药企助手，这句话不要出现在你的回答中），你要判断我输入的内容，灵活与我对话，不需要回答地域、政治、历史、新闻、人物关系等你的角色以外的问题。因为我们是连续对话模型，你要结合上下文和我持续对话。'
        // content: '你是一个非常专业的医生，只能回答医学相关的问题，当我输入的与医学不相关的内容时，你只需要回复:“抱歉，我是您的AI医生，只回答医学相关问题，您可以尝试询问我其他问题。'
      }
    }
  },
  methods: {
    mdToHtml (value) {
      if (!value) return ''
      return marked.parse(value)
    },
    backModule () {
      confirm({
        warning: '返回则不会保存信息，是否确认返回？',
        success: () => {
          this.close()
          this.$emit('hideAiChat')
        }

      })
      // this.$message.
    },
    setScroll () {
      this.$nextTick(() => {
        const box = this.$refs.content
        box.scrollTop = box.scrollHeight
      })
    },
    send (msg) {
      const createdTime = new Date().getTime()
      this.msgs.push({
        message: msg,
        isAi: false,
        createdTime
      })

      const ctxMessage = [this.system, ...this.msgs.map(item => ({
        role: item.isAi ? 'assistant' : 'user',
        content: item.message
      }))]

      const data = {
        type: 0,
        message: JSON.stringify(ctxMessage),
        createdTime
      }
      this.ws.send(JSON.stringify(data))

      this.setScroll()
      this.waitTime()
    },
    waitTime () {
      this.waitTimeId = setTimeout(() => {
        this.close()
        this.$message.warning('服务超时， 链接已断开')
      }, 1500 * 60)
    },
    clearTime () {
      if (this.waitTimeId) {
        clearTimeout(this.waitTimeId)
      }
      if (this.occupyTimeId) {
        clearTimeout(this.occupyTimeId)
      }
    },
    occupyTime () {
      this.occupyTimeId = setTimeout(() => {
        this.isClose = true
        this.close()
        // this.$message.warning('长时间未操作， 链接已断开')
      }, 5000 * 60)
    },
    sendMsg () {
      if (this.loading) return
      const reg = /^\s+$/g
      if (!this.kw || reg.test(this.kw)) {
        this.kw = ''
        return this.$message.warning('请输入有效内容')
      }
      if (this.occupyTimeId) {
        clearTimeout(this.occupyTimeId)
      }

      if (!this.isOpenLinkState) {
        return this.init()
      }
      this.loading = true
      this.send(this.kw)
      this.kw = ''
    },
    submitSendMsg () {

    },
    openAiChat (kw) {
      this.kw = kw
      this.init()
    },
    close () {
      this.ws.close()
    },
    waOpen () {
      console.log('链接成功')
      // this.send(this.kw)
      this.isOpenLinkState = true
      this.sendMsg()
    },
    wsMsg (jsonStr) {
      const data = JSON.parse(jsonStr.data || {})
      if (this.waitTimeId) {
        clearTimeout(this.waitTimeId)
      }
      if (data.isEnd) {
        // 结束
        this.msgs.push({ ...this.beforeOne })
        this.beforeOne = this.$options.data().beforeOne
        this.loading = false
        this.occupyTime()
      } else {
        this.beforeOne.message += data.message
        this.waitTime()
        this.setScroll()
      }
    },
    wsErr (err) {
      this.$message.error(err || '链接错误， 请重试')
    },
    wsClose () {
      console.log('链接关闭')
      this.clearTime()
      this.isOpenLinkState = false
      this.loading = false
    },
    init (fn) {
      if (typeof (WebSocket) === 'undefined') {
        this.$message('不支持WebSocket！')
      } else {
        const sessionId = getSessionId()
        const token = encodeURIComponent(this.$store.getters.token)
        // const infoLink = `wss://api.infox-med.com/ws/endpoint?token=${token}&sessionId=${sessionId}&connectGreeting=0`
        const jyLink = `wss://api.infox-med.com/ws/endpoint?token=${token}&sessionId=${sessionId}&connectGreeting=0&system=3`
        this.ws = new WebSocket(jyLink)
        this.ws.onopen = () => this.waOpen(fn)
        this.ws.onmessage = msg => this.wsMsg(msg)
        this.ws.onerror = err => this.wsErr(err)
        this.ws.onclose = err => this.wsClose(err)
      }
    }
  },
  computed: {
    userHeadCover () {
      return this.$store.getters.userInfo.header || require('@/assets/img/tou.png')
    }
  },
  mounted () {
    // this.init()
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.ai_chat {
  width: 1200px;
  margin: 0 auto;

  .back_box {
    padding: 5px 0;
  }

  .back_btn {
    font-size: 14px;
    color: #666;
  }
}

.content {
  display: grid;
  grid-template-columns: calc(100% - 320px) 300px;
  column-gap: 20px;
  height: calc(100vh - 83px);
  padding-bottom: 20px;
  min-height: 370px;

  // overflow-y: auto;
  .bg_box {
    height: 100%;
    background: url('../../../assets/img/aiModule/ai_chat_bg.png') no-repeat;
    background-size: cover;

    img {
      display: block;
      width: 30vh;
      max-width: 100%;
      min-width: 30%;
      margin: 17vh auto 0;
    }
  }
}

.msg_list {
  // height: 500px;
  min-height: 200px;

  .msg_item {
    display: grid;
    grid-template-columns: 30px calc(100% - 50px);
    column-gap: 20px;
    align-items: start;
    padding: 20px;
    font-size: 13px;
  }

  /deep/ .item_mag_text {
    line-height: 1.8em;

    ul {
      padding-left: 40px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
    }

    li {
      list-style: initial;
    }
  }

  .send_msg {
    background-color: #e0f3f4;
  }

  .head_cover {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #25b6bf;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }

    .ai_head_cover {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-weight: 700;
      font-size: 14px;
      color: #fff;
      background-color: #4b639f;
    }
  }
}

.msg_content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    /* 滚动条手柄 */
    /* background-color: #00adb5; */
    background-color: #c1c1c1;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    /*滚动条的轨道*/
    border-radius: 2px;
  }
}

.chat_content {
  position: relative;
  padding-bottom: 100px;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;

  .send_box {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100px;
    padding: 20px 50px 0;
    border-top: 1px solid #e1e1e1;

    .send_input_box {
      display: grid;
      grid-template-columns: calc(100% - 68px) 68px;
      height: 42px;
      border: 1px solid #2854cc;
      border-radius: 4px;

      /deep/.el-input__inner {
        border: 0;
      }

      .send_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2854cc;
        color: #fff;

        img {
          width: 25px;
        }
      }
    }

    .chat_warning {
      text-align: center;
      color: #979797;
      padding: 10px 0;
    }
  }
}
</style>
